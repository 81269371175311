import {roundUpToPrecision} from "./roundUpToPrecision";
import {roundDownToPrecision} from "./roundDownToPrecision";

export const roundToPrecision = (value, precision) => {

  if (isNaN(Number(value))) {
    return value;
  }

  let modulo = value % precision;
  if (modulo === 0) {
    return value;
  } else {
    return precision / 2 <= modulo ?
      roundUpToPrecision(value, precision) :
      roundDownToPrecision(value, precision);
  }
};