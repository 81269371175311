import cloneObject from "./cloneObject";
import getOptionById from "./getOptionById";

export default function (mainBlock, overrideBlock) {
  // Blocks to be merged must be of the same type
  if (mainBlock['machine_name'] !== overrideBlock['machine_name']) {
    throw new Error(`Blocks to be merged (${mainBlock['machine_name']}, ${overrideBlock['machine_name']}) must be of the same type`);
  }

  const resultBlock = cloneObject(mainBlock);
  overrideBlock = cloneObject(overrideBlock);

  // Write fields (except for "values") from "override" to the "main" block
  Object.keys(overrideBlock).forEach(field => {
    if (field !== "values"){
      resultBlock[field] = overrideBlock[field];
    }
  });

  // Write options fields from "override" to the "main" block
  if (overrideBlock.hasOwnProperty('values')) {
    const resultValues = resultBlock.values;
    const overrideValues = overrideBlock.values;

    // Block type cannot be overwritten
    if (overrideValues.hasOwnProperty('type')) {
      if (resultValues.type !== overrideValues.type) {
        throw new Error(`Block type cannot be overwritten`)
      }
    }

    if (overrideValues.hasOwnProperty('options')) {
      const overrideOptions = overrideValues.options;

      overrideOptions.forEach(overrideOption => {
        //  Each option must contain a "machine_name" field
        if (!overrideOption.hasOwnProperty('machine_name')) {
          throw new Error(`The option "${overrideOption.label}" in block "${overrideBlock.machine_name}" does not contain a "machine_name" field`);
        }

        const id = overrideOption.machine_name;
        const resultOption = getOptionById(resultBlock, id);

        Object.keys(overrideOption).forEach(field => {
          resultOption[field] = overrideOption[field]
        })
      })
    }
  }

  return resultBlock;
}