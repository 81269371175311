export const getDeliveryValues = (dataValue, region, houseId, country) => {
  if (typeof dataValue !== 'object') {
    return {
      price: dataValue,
    };
  }

  if (typeof country !== "undefined") {
    if (!dataValue.hasOwnProperty(country)) {
      throw new Error(`No shipping cost for the country (${country})`)
    }

    return {
      price: dataValue[country]
    }
  }

  if (typeof region !== "undefined" &&
      typeof houseId !== "undefined") {
    if (dataValue.hasOwnProperty('all')) {
      return {
        price: dataValue.all[houseId],
      };
    }
  }

  return {
    price: dataValue.value,
    defaultValue: dataValue.default,
  };
};
