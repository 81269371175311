export const getVerandaValues = (optionData, region, houseId) => {
  // Todo: Add condition for small houses
  const smallHouses = ['kh26', 'dh26'];

  const valueData = optionData.value;
  const result = {
    width: undefined,
    height: undefined,
    price: undefined,
    foundationPrice: undefined,
  };

  if (typeof valueData !== 'object') {
    result.price = valueData;
  } else {

    // If option has foundation price
    if (valueData.hasOwnProperty('foundation_price')) {
      if (typeof valueData.foundation_price === 'object') {
        if (valueData.foundation_price.hasOwnProperty('all')) {
          region = 'all';
        }
        result.foundationPrice = valueData.foundation_price[region][houseId];
      } else {
        result.foundationPrice = valueData.foundation_price;
      }
    }

    if (valueData.hasOwnProperty('price')) {
      result.price = valueData.price;
    } else if (valueData.hasOwnProperty('small')) {
      if (smallHouses.includes(houseId)) {
        result.width = valueData.small.width;
        result.height = valueData.small.height;
        if (typeof valueData.small.price === "object") {
          if (valueData.small.price.hasOwnProperty('all')) {
            region = 'all';
          }
          result.price = valueData.small.price[region][houseId];
        } else {
          result.price = valueData.small.price;
        }
      } else {
        result.width = valueData.big.width;
        result.height = valueData.big.height;
        if (typeof valueData.big.price === "object") {
          if (valueData.big.price.hasOwnProperty('all')) {
            region = 'all';
          }
          result.price = valueData.big.price[region][houseId];
        } else {
          result.price = valueData.big.price;
        }
      }
    } else {
      result.price = valueData[houseId];
    }
  }

  return result;
};
