import cloneObject from "./cloneObject";
import mergeBlocks from "./mergeBlocks";

export default function (mainData, overrideData) {
  const resultData = cloneObject(mainData);
  overrideData = cloneObject(overrideData);

  // Override houses
  if (overrideData.hasOwnProperty('houses')) {
    const resultHouses = resultData.houses;
    const overrideHouses = overrideData.houses;

    Object.keys(overrideHouses).forEach( houseId => {

      const house = overrideHouses[houseId];

      if (house.hasOwnProperty('display')) {
        if (!house['display']) {
          delete resultHouses[houseId];
          return;
        }
      }

      Object.keys(house).forEach( field => {
        if (!resultHouses[houseId]) {
          resultHouses[houseId] = {};
        }

        resultHouses[houseId][field] = house[field];
      })
    })
  }

  // Override blocks
  if (overrideData.hasOwnProperty('blocks')) {
    const resultBlocks = resultData.blocks;
    const overrideBlocks = overrideData.blocks;

    overrideBlocks.forEach(overrideBlock => {
      const resultBlockIndex = resultBlocks.findIndex( block => (
        block['machine_name'] === overrideBlock['machine_name']
      ));

      if ( resultBlockIndex === -1) {
        resultData.blocks.push(overrideBlock);
      } else {
        const resultBlock = resultBlocks[resultBlockIndex];
        resultBlocks[resultBlockIndex] = mergeBlocks(resultBlock, overrideBlock);
      }

    })
  }

  //  Override configurations
  if (overrideData.hasOwnProperty('configuration')) {
    const resultConfig = resultData.configuration;
    const overrideConfig = overrideData.configuration;

    Object.keys(overrideConfig).forEach( field => {
      resultConfig[field] = overrideConfig[field];
    })
  }

  return resultData;
}