export default function (value, markupPercentage = 0) {
  if (markupPercentage === 0){ return value }
  const price = Number(value);

  // If value contain a letter return value
  if (isNaN(price)) {
    return value;
  }

  const extraPrice = price * markupPercentage / 100;

  return price + extraPrice;
}