import {getInputValues} from "./getInputValues";
import {getDeliveryValues} from "./getDeliveryValues";
import {getFoundationValues} from "./getFoundationValues";
import {getStairsValues} from "./getStairsValues";
import {getTerraceValues} from "./getTerraceValues";
import {getPorchValues} from "./getPorchValues";

export function initialCheckboxesBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
      blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    const blockOptions = blockData.values.options;
    blockOptions.forEach((option, index) => {
      blockValue[index] = {};
      blockValue[index].checked = !!option.checked;
    });
  }

  return blockValue;
}

export function initialAddBathroomBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    blockData.values.options.forEach((option, index) => {
      blockValue[index] = {};
      const { defaultValue } = getInputValues(option.value);
      blockValue[index].value = defaultValue;
    });
  }

  return blockValue;
}

export function initialCheckboxInputBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    blockData.values.options.forEach((option) => {
      const index = option['machine_name'];
      blockValue[index] = {};
      blockValue[index].checked = !!option.checked;
      if (option.type === 'input') {
        const { defaultValue } = getInputValues(option.value);
        blockValue[index].value = defaultValue;
      }
    });
  }

  return blockValue;
}

export function initialDeliveryBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    const { options } = blockData.values;
    options.forEach((option) => {
      const id = option['machine_name'];
      blockValue[id] = {};
      blockValue[id].checked = true;

      switch (id) {
        case '1': {
          const { defaultValue } = getDeliveryValues(option.value);
          blockValue[id].value = defaultValue;
          break;
        }

        case '2': {
          blockValue[id].checked = false;
          break;
        }
      }
    });
  }

  return blockValue;
}

export function initialEquipmentRentalBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    const { options } = blockData.values;
    options.forEach((option, index) => {
      blockValue[index] = {};
      const { defaultValue } = getInputValues(option.value);
      blockValue[index].value = defaultValue;
    });
  }

  return blockValue;
}

export function initialFoundationRentalBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    const {options} = blockData.values;
    options.forEach((option) => {
      const machineName = option['machine_name'];
      blockValue[machineName] = {};
      blockValue[machineName].checked = false;

      switch (machineName) {
        case '0':
          const selectIndex = 0;
          blockValue[machineName].checked = true;
          blockValue[machineName].value = selectIndex;
          break;

        case '1':
          const { price } = getFoundationValues(option.value);
          blockValue[machineName].value = price;
          break;

        case '3':
        case '5':
          const {defaultValue} = getFoundationValues(option.value);
          blockValue[machineName].value = defaultValue;
          break;
      }
    });
  }

  return blockValue;
}

export function initialHomeInstallationBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    const { options } = blockData.values;
    options.forEach((option) => {
      const id = option['machine_name'];
      blockValue[id] = {};
      blockValue[id].checked = false;

      if (id === '3') {
        blockValue[id].value = 0;
      }
    });
  }

  return blockValue;
}

export function initialInputsBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    blockData.values.options.forEach((option, index) => {
      blockValue[index] = {};
      const { defaultValue } = getInputValues(option.value);
      blockValue[index].value = defaultValue;
    });
  }

  return blockValue;
}

export function initialPorchBlock(blockData, blocksFromState) {

  let blockValue = {};

  const { options } = blockData.values;

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    options.forEach((option, index) => {
      blockValue[index] = {};
      blockValue[index].checked = !!option.checked;

      let { value } = getPorchValues(option.value);
      blockValue[index].value = value;
    });
  }

  return blockValue;
}

export function initialRadioButtonsBlock(blockData, blocksFromState) {

  let blockValue = {
    active_index: 0,
  };

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    blockData.values.options.find((item, index) => {
      if (item.checked) {
        blockValue.active_index = index;
        return true;
      }
      return false;
    });
  }

  return blockValue;
}

export function initialStairsBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = blocksFromState[blockData.machine_name];
  } else {
    blockData.values.options.forEach((item) => {
      const id = item['machine_name'];
      if (typeof id === "undefined") {throw "Option has not a machine_name field"}
      blockValue[id] = {};
      const { width, steps } = getStairsValues(item.value);

      blockValue[id].width = width;
      blockValue[id].steps = steps;
    });

    return blockValue;
  }

  return blockValue;
}

export function initialTerraceBlock(blockData, blocksFromState) {

  let blockValue;

  const optionValue = blockData.values.options[0].value;

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    const { defaultValue } = getTerraceValues(optionValue);
    blockValue = {
      0: {
        value: defaultValue,
      },
    };
  }

  return blockValue;
}

export function initialVerandaBlock(blockData, blocksFromState) {

  let blockValue = {
    active_index: 0,
  };

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    blockData.values.options.find((item, index) => {
      if (item.checked) {
        blockValue.active_index = index;
        return true;
      }
      return false;
    });
  }

  return blockValue;
}

export function initialCustomOptionsBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    blockData.values.options.forEach((item) => {
      const id = item['machine_name'];
      blockValue[id] = {};

      if (typeof id === "undefined") {throw "Option has not a machine_name field"}
      blockValue[id].text = item.label;
      blockValue[id].value = item.value;
    });

    return blockValue;
  }

  return blockValue;
}

export function initialRadioCheckboxesBlock(blockData, blocksFromState) {

  let blockValue = {};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    blockData.values.options.forEach((option) => {
      const id = option['machine_name'];
      if (typeof id === "undefined") {throw new Error("Option has not a machine_name field")}
      const optionType = option.type;
      if (typeof optionType === "undefined") {throw new Error("Option has not a type field")}

      if (optionType === 'checkbox') {
        blockValue[id] = {};
        blockValue[id].checked = !!option.checked;
      } else {
        if (!blockValue.hasOwnProperty('active_index')) {
          blockValue.active_index = id;
        }
        if (option.checked) {
          blockValue.active_index = id;
        }
      }
    });
  }

  return blockValue;
}

export function initialArchitecturalProjectBlock(blockData, blocksFromState) {

  let blockValue = {active_index: 0};

  if (typeof blocksFromState !== "undefined" &&
    blocksFromState.hasOwnProperty(blockData.machine_name))
  {
    blockValue = { ...blocksFromState[blockData.machine_name] };
  } else {
    blockData.values.options.forEach((option) => {
      const id = option['machine_name'];
      if (typeof id === "undefined") {throw new Error("Option has not a machine_name field")}

      if (option.checked) {
        blockValue.active_index = id;

        if (option.hasOwnProperty('sub_options')) {
          option['sub_options'].forEach(subOption => {
            const subId = subOption['machine_name'];
            blockValue[subId] = {};
            blockValue[subId].checked = !!subOption.checked;
          });
        }
      }

    });
  }

  return blockValue;
}

export function initialInputCheckboxesBlock(blockData, blocksFromState) {
      let blockValue = {};
      if (typeof blocksFromState !== "undefined" &&
        blocksFromState.hasOwnProperty(blockData.machine_name))
      {
        blockValue = { ...blocksFromState[blockData.machine_name] };
      } else {
        blockData.values.options.forEach((option, index) => {
          blockValue[index] = {};
          switch (option.type) {
            case 'input': {
              const { defaultValue } = getInputValues(option.value);
              blockValue[index].value = defaultValue;
              break;
            }
            case 'checkbox': {
              blockValue[index].checked = !!option.checked;
              break;
            }
          }
        });
      }
 
  return blockValue;
}