export default function (blockData, optionId) {
  const blockOptions = blockData.values.options;

  let result = blockOptions.find(option => (
    option['machine_name'] === optionId
  ));

  if (typeof result === "undefined") {
    result = blockOptions[optionId];
  }

  if (typeof result === "undefined") {
    throw new Error(`Could not find option with id "${optionId}" in block "${blockData}"`);
  }

  return result;
}