
export default function optionValueChanger(optionValue, callback, immutable_props = []) {
  const valueType = typeof optionValue;

  switch (valueType) {
    case "string":
    case "number": {

      return callback(optionValue);
    }

    case "object": {

      const immutableProp = [
        'width',
        'height',
        'default',
        'repeat_number',
        'width',
        'steps',
      ].concat(immutable_props);

      let resultObj = Object.assign({}, optionValue);

      Object.keys(resultObj).forEach(key => {

        if (!immutableProp.includes(key)) {

          resultObj[key] = optionValueChanger(resultObj[key], callback, immutable_props)
        }
      });

      return resultObj;
    }
  }
}