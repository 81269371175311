export const getTerraceValues = (dataValue, region, houseId) => {

  let result = {
    priceValue: dataValue.unit_price,
    defaultValue: dataValue.default,
    cost_per_m2: undefined,
  };

  if (typeof dataValue !== 'object') {
    return {
      priceValue: dataValue,
    };
  }

  if (typeof region !== "undefined") {
    result['cost_per_m2'] = dataValue[region].cost_per_m2;
  }

  return result;
};
