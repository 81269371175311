import {
  checkboxesPriceCalculation,
  deliveryPriceCalculation,
  foundationPriceCalculation,
  checkboxInputPriceCalculation,
  homeInstPriceCalculation,
  inputPriceCalculation,
  porchPriceCalculation,
  radioPriceCalculation,
  terracePriceCalculation,
  verandaPriceCalculation,
  stairsPriceCalculation,
  addBathroomPriceCalculation,
  customOptionsPriceCalculation,
  radioCheckboxesPriceCalculation, 
  architecturalProjectCalculation,
  inputCheckboxesPriceCalculation
} from './blocksPriceCalculation';

export default function (block, blockValue, store) {
  const blockType = block.values.type;

  switch (blockType) {
    case 'radio': return radioPriceCalculation(block, blockValue, store);
    case 'checkboxes': return checkboxesPriceCalculation(block, blockValue, store);
    case 'equipment_rental':
    case 'input': return inputPriceCalculation(block, blockValue, store);
    case 'checkbox_input': return checkboxInputPriceCalculation(block, blockValue, store);
    case 'add_bathroom': return addBathroomPriceCalculation(block, blockValue, store);
    case 'veranda': return verandaPriceCalculation(block, blockValue, store);
    case 'terrace': return terracePriceCalculation(block, blockValue, store);
    case 'porch': return porchPriceCalculation(block, blockValue, store);
    case 'stairs': return stairsPriceCalculation(block, blockValue, store);
    case 'delivery': return deliveryPriceCalculation(block, blockValue, store);
    case 'home_installation': return homeInstPriceCalculation(block, blockValue, store);
    case 'foundation': return foundationPriceCalculation(block, blockValue, store);
    case 'custom': return customOptionsPriceCalculation(block, blockValue, store);
    case 'radio_checkboxes': return radioCheckboxesPriceCalculation(block, blockValue, store);
    case 'architectural_project': return architecturalProjectCalculation(block, blockValue, store);
    case 'input_checkboxes': return inputCheckboxesPriceCalculation(block, blockValue, store);

    default: throw new Error(`The block ${blockType} cannot be calculated`);
  }
}