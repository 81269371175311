import override from '../data/data.override'
import data from '../data/data'

export default function () {
  if (override.hasOwnProperty('configuration')) {

    const configuration = override['configuration'];
    if (configuration.hasOwnProperty('offer_type')){

      return configuration['offer_type'].toLowerCase();
    }
  } else {
    if (data.hasOwnProperty('configuration')) {

      const configuration = data['configuration'];
      if (configuration.hasOwnProperty('offer_type')){

        return configuration['offer_type'].toLowerCase();
      }
    }
  }

  return 'dealer';
}