export const getFoundationValues = (dataValue, region, houseId) => {
  if (typeof dataValue === 'undefined') {
    return {
      price: 0,
    };
  }
  if (typeof dataValue !== 'object') {
    return {
      price: dataValue,
    };
  }

  if (dataValue.hasOwnProperty('cost_per_km')) {
    return {
      price: dataValue.cost_per_km,
      defaultValue: dataValue.default,
    };
  }

  if (dataValue.hasOwnProperty('all')) {
    region = 'all';
  }

  if (typeof region !== "undefined" &&
      typeof houseId !== "undefined")
  {
    return {
      price: dataValue[region][houseId],
    };
  }

  return {
    price: 0
  }
};
