export const getInputValues = (dataValue, region, houseId) => {

  const result = {
    priceValue: undefined,
    defaultValue: dataValue.default,
  };

  if (typeof region !== "undefined" &&
      typeof houseId !== "undefined")
  {
    if (dataValue.hasOwnProperty('all')) {
      region = 'all';
    }

    if (typeof dataValue[region] === 'object') {
      result.priceValue = dataValue[region][houseId];
    } else {
      result.priceValue = dataValue[region];
    }
  }

  result.priceValue = parseFloat(result.priceValue);

  return result;
};
