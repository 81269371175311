export const getPorchValues = (dataValue, region, houseId) => {
  const result = {
    value: undefined,
    unitPrice: undefined,
    foundation_price: undefined,
  };

  if (dataValue.hasOwnProperty('all')) {
    region = 'all';
  }

  if ( !(region || houseId) ) {
      result.value = dataValue.default
  } else {

    if (region === 'all') {
      result.unitPrice = dataValue[region];
    } else {
      result.unitPrice = dataValue[region].all;
    }
    result.value = dataValue.default;

    if (dataValue[region].hasOwnProperty('foundation_price')) {
      result.foundation_price = dataValue[region].foundation_price;
    }
  }

  return result
};
