import getData from "../../utils/getData";

const getInitialState = () => {
  const data = getData();
  const { houses, configuration } = data;
  const { regions, countries } = configuration;

  let country = Object.keys(countries).sort()[0];

  const houseId = Object.keys(houses)[0];
  const language = country === 'by' ||
                   country === 'msk' ? 'ru': 'en';
  const region = Object.keys(regions).find(region => (
    regions[region].includes(country)
  ));

  if (typeof region === "undefined"){
    throw new Error(`The country "${country}" does not have a region`);
  }
  const { city, distance } = countries[country];

  return {
    country: country,
    language: language,
    region: region,
    houseId: houseId,
    DDP_distance: distance,
    DDP_city: city,
    area: {},
    blocks: {},
  }
};

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case 'UPDATE_STORE': {
      return Object.assign({}, state);
    }

    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        language: action.language,
      };

    case 'CHANGE_COUNTRY':
      const data = getData();
      const { regions } = data.configuration;
      const { countries } = data.configuration;
      const region = Object.keys(regions).find(region => (
        regions[region].includes(action.country)
      ));

      if (typeof region === "undefined"){
        throw new Error(`The country "${action.country}" does not have a region`);
      }

      return {
        ...state,
        country: action.country,
        region: region,
        DDP_city: countries[action.country].city,
        DDP_distance: countries[action.country].distance,
      };

    case 'SET_ACTIVE_HOUSE':
      return {
        ...state,
        houseId: action.houseId,
      };

    case 'UPDATE_BLOCK_VALUE':
      const newState = { ...state };
      newState.blocks[action.blockId] = action.value;
      return newState;

    case 'UPDATE_AREA_VALUE':
      const newArea = { ...state.area };
      newArea[action.optionName] = action.value;
      return {
        ...state,
        area: newArea,
      };

    case 'DELETE_BLOCK_VALUE':
      delete state.blocks[action.blockId];
      delete state.area[action.blockId];

      return { ...state };

    default:
      return state;
  }
};
