/**
 *
 * @param dataValue
 * @param region
 * @param houseId
 * @returns {null|*}
 */
export const getPriceValue = (dataValue, region, houseId) => {
  if (typeof dataValue !== 'object') {
    return dataValue;
  }
  if (!dataValue.hasOwnProperty(region) && !dataValue.hasOwnProperty('all')) {
    return null;
  }
  if (dataValue.hasOwnProperty('all')) {
    region = 'all';
  }

  if (typeof dataValue[region] !== 'object') {
    return dataValue[region];
  }

  return dataValue[region].hasOwnProperty(houseId) ? dataValue[region][houseId] : null;
};
