export const roundDownToPrecision = (value, precision) => {

  if (isNaN(Number(value))) {
    return value;
  }

  let modulo = value % precision;
  if (modulo === 0) {
    return value;
  } else {
    return value - modulo;
  }
};